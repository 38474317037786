input,
select,
textarea {
  background-color: #efecffa4 !important; // Light shade to make the input visible
  border: 1px solid #ccc; // Border to distinguish it
  padding: 8px;
  color: #333; // Text color
  &:focus {
    background-color: #fff; // Slightly lighter when focused
    border-color: #007bff; // Change border color on focus
  }

  &:disabled {
    background-color: #a9a9a98c !important; // Different shade for disabled input
    border-color: #d6d6d6;
    cursor: not-allowed; // Changes cursor to indicate it's disabled
    opacity: 0.65; // Reduce opacity to make it look disabled
  }
}
