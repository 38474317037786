//
// _layouts.scss
//

body[data-layout-size="boxed"] {
  background-color: $boxed-body-bg;
  #layout-wrapper {
    background-color: var(--#{$prefix}body-bg);
    max-width: $boxed-layout-width;
    margin: 0 auto;
    box-shadow: $box-shadow;
  }

  #page-topbar {
    max-width: calc(#{$boxed-layout-width} - #{$sidebar-width});
    margin: 0 auto;
  }

  .footer {
    margin: 0 auto;
    max-width: calc(#{$boxed-layout-width} - #{$sidebar-width});
  }

  body[data-sidebar-size="sm"] {
    #page-topbar {
      max-width: calc(#{$boxed-layout-width} - #{$sidebar-collapsed-width});
    }
    .footer {
      max-width: calc(#{$boxed-layout-width} - #{$sidebar-collapsed-width});
    }
  }
}

// Horizontal Boxed Layout

body[data-layout="horizontal"][data-layout-size="boxed"] {
  #page-topbar,
  #layout-wrapper,
  .footer {
    max-width: 100%;
  }
  .container-fluid,
  .navbar-header {
    max-width: $boxed-layout-width;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loader {
  border: 8px solid #f3f3f3;
  border-top: 8px solid #2f374e;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 1s linear infinite;
}
